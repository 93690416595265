<template>
  <div
    class="wish-btn"
    @click="handlerWishAdd"
  >
    <img
      v-if="isAddedWish"
      src="~/assets/img/svg/wish/heart-fill.svg"
      alt="wish"
    >
    <img
      v-else
      src="~/assets/img/svg/wish/heart.svg"
      alt="wish-1"
    >
  </div>
</template>

<script setup lang="ts">
import useSitisWish from '#sitis/composables/useSitisWish';
import {useCustomNotificationStore} from "~/store/customNotification";

const { productId } = defineProps<{
	productId:number;
}>();
const {
  getWishList,
  removeItemFromWishList,
  addToWishList,
  isInWish
} = useSitisWish();

const {
  openCustomNotification,
} = useCustomNotificationStore();

const openPopupCustomNotification = function() {
  openCustomNotification({
    title: 'Избранное',
    message: 'Коллекция добавлена в избранное',
  });
}

const isAddedWish = computed(() => isInWish(productId));

const handlerWishAdd = async () => {
  isAddedWish.value ? await removeItemFromWishList(productId) : await addToWishList({ id: productId });
  await getWishList({ extensions: ['short_characteristics'] });

  if (isAddedWish.value) {
    openPopupCustomNotification();
  }
};

</script>

<style scoped lang="scss">
.wish-btn{
  cursor:pointer;
	display: flex;
	justify-content: center;
	align-items: center;

  & > img{
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 990px) {
  .wish-btn img {
    width: 24px;
    height: 24px;
  }
}
</style>
