import { getCharacteristicFromFull } from '~/utils/getCharacteristic';
import extractSubstrings from '~/utils/extractSubstrings';

const getDiscountLabel = (product) => {
  const characteristics = (product?.shortCharacteristics || []);
  if (characteristics.length <= 0) {
    (product?.characteristics || [])?.forEach((item) => {
      characteristics.push(...(item.characteristics || []));
    });
  }

  const charDiscountLabel = (characteristics || []).find((t) => t.slug === 'discount-label')?.values?.[0];
  const isHiddenLabel = Boolean((characteristics || []).find((t) => t.slug === 'skryvat-lejbl-so-skidkoj')?.values?.[0]?.slug === 'da');
  if (!!isHiddenLabel) {
    return _getFirstPromotion(product)
  }

  if (charDiscountLabel && !isHiddenLabel) {
    const discountLabelMain = extractSubstrings((charDiscountLabel?.value || ''), '<p>', '</p>');
    const discountLabelDate = extractSubstrings((characteristics || []).find((t) => t.slug === 'discount-label-date')?.values?.[0]?.value || '', '<p>', '</p>');
    const discountLabelTooltip = (characteristics || []).find((t) => t.slug === 'lejbl-dlya-skidki-polnoe-opisanie')?.values?.[0];

    if (!discountLabelMain) {
      return {label: '', tooltip: ''}
    }
    return {
      label: [discountLabelMain, discountLabelDate].filter((t) => !!t).join(' '),
      tooltip: discountLabelTooltip?.value
    };
  }

  const isProductDiscount = Boolean(product?.oldPrice && product?.oldPrice > product?.price);
  console.log('product: ', product);
  if (!isProductDiscount) {
    return _getFirstPromotion(product)
  }

  const isDiscountLabelAuto = Boolean(String((characteristics || []).find((t) => t.slug === 'discount-label-auto')?.values?.[0]?.slug) === '1');
  if (!isDiscountLabelAuto) {
    return _getFirstPromotion(product)
  }

  const price = product?.price;
  const oldPrice = product?.oldPrice;
  return {
    label: `Скидка ${Math.abs(Math.round((oldPrice - price) / oldPrice * 100))}%`,
    tooltip: ''
  };
};
const _getFirstPromotion = (product) => {
  let _promotions = (product?.promotions || []);
  _promotions = _promotions.filter((t) => !Boolean(t?.dynamicFields?.is_additional));
  _promotions = _promotions.sort((a, b) => {
    const aSort = Number.parseFloat(a?.sort || '1');
    const bSort = Number.parseFloat(b?.sort || '1');

    if (aSort < bSort) {
      return 1
    }
    if (aSort > bSort) {
      return -1
    }
    return 0
  });

  return {
    label: _promotions?.[0]?.label,
    tooltip: _promotions?.[0]?.description
  }
};
export default getDiscountLabel;
